var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{attrs:{"color":"info","title":"Photography"}},[_c('p',[_c('font',{attrs:{"size":"4"}},[_vm._v(" Photography is a hobby of mine that helps me get out of the office and explore my surroundings more. Below, you can find some of the photos I've taken. I primarily shoot on a Canon EOS Rebel T5, but also own a Pixel 3, whose camera is great for low light shots and on the go photography. I like to shoot a mixture of subjects, ranging from nature, to objects, to portraits. A majority of the photos I take are edited through Adobe Lightroom and Adobe Photoshop. ")])],1)])],1),_c('v-col',{attrs:{"lg":"4","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"mx-auto"},[_c('v-img',{attrs:{"src":"https://i.imgur.com/pDLQoNk.jpg","aspect-ratio":"0.73"}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out blue-grey darken-2 v-card--reveal subheading white--text",staticStyle:{"height":"10%"}},[_vm._v(" Taken on Pixel 3 ")]):_vm._e()])],1)],1)}}])})],1),_c('v-col',{attrs:{"lg":"4","cols":"12"}},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"mb-n12",attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"mx-auto pa-0"},[_c('v-img',{attrs:{"src":"https://live.staticflickr.com/65535/33930752078_0b8ece950c_h.jpg"}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out blue-grey darken-2 v-card--reveal subheading white--text",staticStyle:{"height":"10%"}},[_vm._v(" Taken on Canon EOS Rebel T5 ")]):_vm._e()])],1)],1)}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"mx-auto pa-0"},[_c('v-img',{attrs:{"src":"https://live.staticflickr.com/1757/40878031840_ff3e01b83a_k.jpg"}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out blue-grey darken-2 v-card--reveal subheading white--text",staticStyle:{"height":"10%"}},[_vm._v(" Taken on Canon EOS Rebel T5 ")]):_vm._e()])],1)],1)}}])})],1)],1)],1),_c('v-col',{attrs:{"lg":"4","cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"mb-n12",attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"mx-auto pa-0"},[_c('v-img',{attrs:{"src":"https://live.staticflickr.com/1730/27818266577_fa5c5b9ea1_k.jpg"}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out blue-grey darken-2 v-card--reveal subheading white--text",staticStyle:{"height":"10%"}},[_vm._v(" Taken on Canon EOS Rebel T5 ")]):_vm._e()])],1)],1)}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"mx-auto pa-0"},[_c('v-img',{attrs:{"src":"https://live.staticflickr.com/65535/46891570435_f99c022c09_k.jpg"}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out blue-grey darken-2 v-card--reveal subheading white--text",staticStyle:{"height":"10%"}},[_vm._v(" Taken on Canon EOS Rebel T5 ")]):_vm._e()])],1)],1)}}])})],1)],1)],1)],1),_c('v-row',{staticClass:"mt-n12"},[_c('v-col',{attrs:{"lg":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"mx-auto"},[_c('v-img',{attrs:{"src":"https://live.staticflickr.com/1743/41794721665_f25f919118_k.jpg"}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out blue-grey darken-2 v-card--reveal subheading white--text",staticStyle:{"height":"10%"}},[_vm._v(" Taken on Canon EOS Rebel T5 ")]):_vm._e()])],1)],1)}}])})],1),_c('v-col',{attrs:{"lg":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"mx-auto"},[_c('v-img',{attrs:{"src":"https://i.imgur.com/dcSIBPc.jpg","aspect-ratio":"1.5"}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out blue-grey darken-2 v-card--reveal subheading white--text",staticStyle:{"height":"10%"}},[_vm._v(" Taken on Pixel 3 ")]):_vm._e()])],1)],1)}}])})],1)],1),_c('v-row',{staticClass:"mt-n12"},[_c('v-col',{attrs:{"lg":"4","cols":"12"}},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"mb-n12",attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"mx-auto pa-0"},[_c('v-img',{attrs:{"aspect-ratio":"1.5","src":"//live.staticflickr.com/65535/51161652522_2ba2ef7163_k.jpg"}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out blue-grey darken-2 v-card--reveal subheading white--text",staticStyle:{"height":"10%"}},[_vm._v(" Taken on Pixel 3 ")]):_vm._e()])],1)],1)}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"mx-auto pa-0"},[_c('v-img',{attrs:{"aspect-ratio":"1.5","src":"//live.staticflickr.com/65535/51161652262_fef69d3e7c_k.jpg"}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out blue-grey darken-2 v-card--reveal subheading white--text",staticStyle:{"height":"10%"}},[_vm._v(" Taken on Pixel 3 ")]):_vm._e()])],1)],1)}}])})],1)],1)],1),_c('v-col',{attrs:{"lg":"4","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"mx-auto"},[_c('v-img',{attrs:{"src":"//live.staticflickr.com/65535/48954904437_e051bc26e9_k.jpg","aspect-ratio":"0.73"}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out blue-grey darken-2 v-card--reveal subheading white--text",staticStyle:{"height":"10%"}},[_vm._v(" Taken on Canon EOS Rebel T5 ")]):_vm._e()])],1)],1)}}])})],1),_c('v-col',{attrs:{"lg":"4","cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"mb-n12",attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"mx-auto pa-0"},[_c('v-img',{attrs:{"src":"//live.staticflickr.com/65535/48954151698_db4121f92b_k.jpg"}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out blue-grey darken-2 v-card--reveal subheading white--text",staticStyle:{"height":"10%"}},[_vm._v(" Taken on Canon EOS Rebel T5 ")]):_vm._e()])],1)],1)}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"mx-auto pa-0"},[_c('v-img',{attrs:{"src":"//live.staticflickr.com/65535/48954701971_0a35e3ce76_k.jpg"}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out blue-grey darken-2 v-card--reveal subheading white--text",staticStyle:{"height":"10%"}},[_vm._v(" Taken on Canon EOS Rebel T5 ")]):_vm._e()])],1)],1)}}])})],1)],1)],1)],1),_c('v-row',{staticClass:"mt-n12"},[_c('v-col',{attrs:{"lg":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"mx-auto pa-0"},[_c('v-img',{attrs:{"src":"//live.staticflickr.com/65535/51161737862_1596d1526e_k.jpg"}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out blue-grey darken-2 v-card--reveal subheading white--text",staticStyle:{"height":"10%"}},[_vm._v(" Taken on Canon EOS Rebel T5 ")]):_vm._e()])],1)],1)}}])})],1),_c('v-col',{attrs:{"lg":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"mx-auto pa-0"},[_c('v-img',{attrs:{"src":"//live.staticflickr.com/65535/48954170058_6ce72d8dfd_k.jpg"}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out blue-grey darken-2 v-card--reveal subheading white--text",staticStyle:{"height":"10%"}},[_vm._v(" Taken on Canon EOS Rebel T5 ")]):_vm._e()])],1)],1)}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }